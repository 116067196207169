// PdfController - export element/document to PDF
//
// @example
//  <div data-controller="pdf">
//    <button data-action="click->pdf#export">
//      Export to PDF
//    </button>
//  </div>

import {Controller} from "stimulus";

declare const html2pdf;

export default class PDFController extends Controller<HTMLElement> {
  static values = {
    document: {type: Boolean, default: false},
    title: {type: String, default: "export"},
  };

  declare documentValue: boolean;
  declare titleValue: string;

  connect() {
    const script = document.createElement("script");
    script.type = "application/javascript";
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js";
    script.integrity = "sha512-GsLlZN/3F2ErC5ifS5QtgpiJtWd43JWSuIgh7mbzZ8zBps+dvLusV+eNQATqgA/HdeKFVgA5v3S/cIrLF7QnIg==";
    script.crossOrigin = "anonymous";
    script.referrerPolicy = "no-referrer";
    document.head.appendChild(script);
    window.addEventListener("beforeprint", this.handlePrintStyles);
    window.addEventListener("afterprint", this.handlePrintStyles);
  }

  disconnect() {
    document.head.querySelector("script[src*='html2pdf.js']")?.remove();
    window.removeEventListener("beforeprint", this.handlePrintStyles);
    window.removeEventListener("afterprint", this.handlePrintStyles);
  }

  /*
    Export

    - Fires an event that alters the CSS of the page
    - Captures the altered page as a PDF
    - Fires another event that returns the page to how it looked previously (hopefully)

    @param - data-pdf-variant-name
    The calling element can take a data-pdf-variant-param="<some-variant-name>" attribute.
    This is used by `handlePrintStyles` to allow more flexibility over how the page is altered before PDF capture.
  */
  export(event) {
    const elementToCapture = this.documentValue ? document.body : this.element;
    const options = {
      margin: this.documentValue ? 0 : 10,
      filename: [this.titleValue, "pdf"].join("."),
    };

    Promise.resolve()
      .then(() =>
        window.dispatchEvent(
          new CustomEvent("beforeprint", {detail: {...event.params}}),
        ),
      )
      .then(() => html2pdf(elementToCapture, options))
      .then(() =>
        window.dispatchEvent(
          new CustomEvent("afterprint", {detail: {...event.params}}),
        ),
      );
  }

  /**
   * Handles print-specific styles by dynamically adding or removing CSS classes
   * based on the print event (`beforeprint` or `afterprint`).
   *
   * @param {Event} event - The print event, expected to contain `event.detail.variant` (optional).
   *
   * @description
   * - Finds elements with classes prefixed by `print:` or `print-<variant>:`.
   * - On `beforeprint`, adds the corresponding non-prefixed classes.
   * - On `afterprint`, removes them to restore the original state.
   * - Supports an optional variant (e.g., `print-anonymous:block`) for scoped print styles.
   *
   * @example
   * _Before being called_
   * ```html
   * <div class="block print:!hidden">Has display block</div>
   * <div class="hidden print-anonymous:!block">is hidden</div>
   * ```
   * _After being called_
   * ```html
   * <div class="block print:!hidden !hidden">This will be hidden when printing</div>
   * <div class="hidden print-anonymous:!block !block">This will become display block, only when the 'anonymous' variant has been set when printing</div>
   * ```
   *
   * Once the PDF has been captured, these added class names are removed.
   */
  handlePrintStyles = (event) => {
    const printVariant = event.detail?.variant || "default";
    const parentElement = this.documentValue ? document.documentElement : this.element.parentElement;

    if (!parentElement) return;
    // Note that these styles are dynamic, and will remove the 'print:' prefix, which may be unexpected, and may lead to classes being added that Tailwind does not recognise.

    parentElement
      .querySelectorAll(`[class*='print:'],[class*='print-${printVariant}:']`)
      .forEach((element) => {
        const classNames = Array.from(element.classList)
          .filter((className) => {
            return (
              className.indexOf("print:") === 0 ||
              className.indexOf(`print-${printVariant}:`) === 0
            );
          })
          .map((className) => className.replace("print:", ""))
          .map((className) => className.replace(`print-${printVariant}:`, ""));

        if (event.type === "beforeprint") {
          classNames.map((className) => element.classList.add(className));
        } else {
          classNames.map((className) => element.classList.remove(className));
        }
      });
  };
}
